import { addClasses, hasClass, removeClasses, toggleClass } from './DOMHelpers';
import { IFrameHelper } from './IFrameHelper';
import { isExpandedView } from './settingsHelper';

export const body = document.getElementsByTagName('body')[0];
export const widgetHolder = document.createElement('div');

export const bubbleHolder =
  document.getElementsByClassName('woot--bubble-holder')[0] ||
  document.createElement('div');
export const chatBubble = document.createElement('button');
export const notificationBubble = document.createElement('span');

export const setBubbleText = bubbleText => {
  if (isExpandedView(window.$hoory.type)) {
    const textNode = document.getElementById('woot-widget--expanded__text');
    if (!textNode) return;

    textNode.innerText = bubbleText;
  }
};

export const createBubbleIcon = ({ className, target, avatarUrl }) => {
  let bubbleClassName = `${className} woot-elements--${window.$hoory.position}`;
  if (target.getAttribute('data-loaded') === 'true') return target;

  const bubbleImageUrl = window.hoorySettings?.avatar || avatarUrl;

  target.setAttribute('data-loaded', 'true');
  const wrapper = document.createElement('div');
  wrapper.setAttribute('class', 'bubble-interaction-wrapper');
  const loadingWrapper = document.createElement('div');
  loadingWrapper.setAttribute('class', 'bubble-loading-wrapper');
  const closeIconWrapper = document.createElement('div');
  closeIconWrapper.setAttribute('class', 'close-icon-wrapper');
  const openIconWrapper = document.createElement('div');
  openIconWrapper.setAttribute('class', 'open-icon-wrapper');

  let openIcon;
  if (bubbleImageUrl) {
    openIcon = document.createElement('img');
    openIcon.setAttribute('src', bubbleImageUrl);
    openIcon.setAttribute('alt', 'Chat Icon');
    openIcon.setAttribute('class', 'woot-widget-bubble-image');
  } else {
    openIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    openIcon.setAttributeNS(null, 'width', '44');
    openIcon.setAttributeNS(null, 'height', '44');
    openIcon.setAttributeNS(null, 'viewBox', '0 0 64 64');
    openIcon.setAttributeNS(null, 'fill', 'none');
    openIcon.setAttributeNS(null, 'class', 'main-icon');
    openIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    // create the first path
    const path1 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    path1.setAttributeNS(
      null,
      'd',
      'M60.8261 33.1875C60.8261 39.0032 56.1531 43.7011 50.4091 43.7011C44.6651 43.7011 39.9921 39.0032 39.9921 33.1875C39.9921 27.3718 44.6651 22.6739 50.4091 22.6739C56.1531 22.6739 60.8261 27.3718 60.8261 33.1875Z'
    );
    path1.setAttributeNS(null, 'stroke-width', '2.34776');
    path1.classList.add('path-1');

    // create the second path
    const path2 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    path2.setAttributeNS(
      null,
      'd',
      'M24.0082 33.1873C24.0082 39.003 19.3351 43.7009 13.5911 43.7009C7.84713 43.7009 3.17411 39.003 3.17411 33.1873C3.17411 27.3715 7.84713 22.6736 13.5911 22.6736C19.3351 22.6736 24.0082 27.3715 24.0082 33.1873Z'
    );
    path2.setAttributeNS(null, 'stroke-width', '2.34776');
    path2.classList.add('path-2');

    // create the third path
    const path3 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    path3.setAttributeNS(
      null,
      'd',
      'M52.6443 38C52.6443 49.5112 43.3924 58.8261 32 58.8261C20.6076 58.8261 11.3557 49.5112 11.3557 38C11.3557 26.4888 20.6076 17.1739 32 17.1739C43.3924 17.1739 52.6443 26.4888 52.6443 38Z'
    );
    path3.setAttributeNS(null, 'stroke-width', '2.34776');
    path3.classList.add('path-3');

    // create the 4th path
    const path4 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    path4.setAttributeNS(
      null,
      'd',
      'M28.778 39.8398C28.4487 40.5557 27.9786 41.1148 27.3677 41.5189C26.7567 41.923 26.0278 42.125 25.1809 42.125C24.3464 42.125 23.6175 41.923 22.9942 41.5189C22.3832 41.1148 21.9131 40.5557 21.5839 39.8398C21.2547 39.1135 21.0909 38.2652 21.0909 37.2951C21.0909 36.3145 21.2547 35.4662 21.5839 34.7504C21.9131 34.0345 22.3832 33.4806 22.9942 33.0887C23.6175 32.6968 24.3464 32.5 25.1809 32.5C26.0278 32.5 26.7567 32.6968 27.3677 33.0887C27.9786 33.4806 28.4487 34.0345 28.778 34.7504C29.1072 35.4662 29.2727 36.3145 29.2727 37.2951C29.271 38.2652 29.1072 39.1135 28.778 39.8398Z'
    );
    path4.classList.add('path-4');

    // create the 5th path
    const path5 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    path5.setAttributeNS(
      null,
      'd',
      'M42.4143 39.8398C42.0851 40.5557 41.615 41.1148 41.004 41.5189C40.3931 41.923 39.6642 42.125 38.8173 42.125C37.9827 42.125 37.2538 41.923 36.6306 41.5189C36.0196 41.1148 35.5495 40.5557 35.2203 39.8398C34.891 39.1135 34.7273 38.2652 34.7273 37.2951C34.7273 36.3145 34.891 35.4662 35.2203 34.7504C35.5495 34.0345 36.0196 33.4806 36.6306 33.0887C37.2538 32.6968 37.9827 32.5 38.8173 32.5C39.6642 32.5 40.3931 32.6968 41.004 33.0887C41.615 33.4806 42.0851 34.0345 42.4143 34.7504C42.7436 35.4662 42.9091 36.3145 42.9091 37.2951C42.9073 38.2652 42.7436 39.1135 42.4143 39.8398Z'
    );
    path5.classList.add('path-5');

    // create the 6th path
    const path6 = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    path6.setAttributeNS(
      null,
      'd',
      'M53.4758 4.28794C51.1778 3.90402 48.832 3.90402 46.534 4.28794C45.4707 4.46637 44.4893 4.97066 43.7255 5.73105C42.963 6.49239 42.4601 7.4745 42.2881 8.53783C41.904 10.8344 41.904 13.1788 42.2881 15.4754C42.4601 16.5387 42.963 17.5208 43.7255 18.2822C44.4893 19.0426 45.4707 19.5469 46.534 19.7253C48.8334 20.0916 51.1764 20.0916 53.4758 19.7253C54.5382 19.5469 55.5184 19.0415 56.2796 18.2796C57.0407 17.5178 57.5449 16.5374 57.7218 15.4754C57.907 14.3306 58.0001 13.1728 58 12.0132C57.9989 10.8509 57.9014 9.69068 57.7085 8.54446C57.5365 7.48499 57.0334 6.50709 56.2712 5.75089C55.5124 4.99077 54.5352 4.48628 53.4758 4.30778V4.28794ZM48.4284 17.2892C48.282 17.293 48.1375 17.2559 48.0109 17.1821C47.8844 17.1084 47.781 17.0009 47.7122 16.8716C47.6434 16.7424 47.612 16.5966 47.6215 16.4505C47.631 16.3044 47.681 16.1639 47.766 16.0447L49.7532 13.3107L46.5009 12.9929C46.3286 12.979 46.1642 12.9144 46.0286 12.8072C45.893 12.7 45.7923 12.555 45.7391 12.3906C45.6827 12.2261 45.6758 12.0488 45.7194 11.8805C45.763 11.7122 45.8551 11.5604 45.9842 11.4439L51.0515 6.90935C51.1954 6.77578 51.385 6.70235 51.5814 6.70414C51.7898 6.70677 51.9892 6.7898 52.1379 6.93586C52.2663 7.06946 52.3443 7.24339 52.3588 7.42806C52.3733 7.61273 52.3233 7.79669 52.2173 7.94866L50.2302 10.6826L53.4825 10.9938C53.6545 11.0105 53.818 11.0768 53.9532 11.1845C54.0884 11.2922 54.1895 11.4367 54.2441 11.6006C54.2988 11.7645 54.3048 11.9408 54.2613 12.108C54.2178 12.2752 54.1267 12.4262 53.9991 12.5428L48.9385 17.084C48.7974 17.2094 48.6171 17.2819 48.4284 17.2892Z'
    );
    path6.classList.add('path-6');

    // append the paths to the SVG element
    openIcon.appendChild(path1);
    openIcon.appendChild(path2);
    openIcon.appendChild(path3);
    openIcon.appendChild(path4);
    openIcon.appendChild(path5);
    openIcon.appendChild(path6);
  }

  const closeIcon = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg'
  );
  closeIcon.setAttributeNS(null, 'width', '24');
  closeIcon.setAttributeNS(null, 'height', '24');
  closeIcon.setAttributeNS(null, 'viewBox', '0 0 24 24');
  closeIcon.setAttributeNS(null, 'fill', 'none');
  closeIcon.setAttributeNS(null, 'class', 'close-icon');
  closeIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  const pathClose = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  pathClose.setAttributeNS(null, 'fill', 'currentColor');
  pathClose.setAttributeNS(
    null,
    'd',
    'm4.21 4.387l.083-.094a1 1 0 0 1 1.32-.083l.094.083L12 10.585l6.293-6.292a1 1 0 1 1 1.414 1.414L13.415 12l6.292 6.293a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.32.083l-.094-.083L12 13.415l-6.293 6.292a1 1 0 0 1-1.414-1.414L10.585 12L4.293 5.707a1 1 0 0 1-.083-1.32l.083-.094z'
  );
  closeIcon.appendChild(pathClose);

  closeIconWrapper.appendChild(closeIcon);
  openIconWrapper.appendChild(openIcon);
  wrapper.appendChild(closeIconWrapper);
  wrapper.appendChild(openIconWrapper);
  target.appendChild(wrapper);
  target.appendChild(loadingWrapper);

  if (isExpandedView(window.$hoory.type)) {
    const textNode = document.createElement('div');
    textNode.id = 'woot-widget--expanded__text';
    textNode.innerText = window.$hoory.launcherTitle;
    target.appendChild(textNode);
    bubbleClassName += ' woot-widget--expanded';
  }

  target.className = bubbleClassName;
  target.title = 'Open chat window';
  return target;
};

export const createBubbleHolder = hideMessageBubble => {
  if (hideMessageBubble) {
    addClasses(bubbleHolder, 'woot-hidden');
  }
  addClasses(bubbleHolder, 'woot--bubble-holder');
  body.appendChild(bubbleHolder);
};

export const createNotificationBubble = () => {
  addClasses(notificationBubble, 'woot--notification');
  return notificationBubble;
};

const waitUntil = async (conditionFn, delay = 1000) => {
  return new Promise(resolve => {
    const result = conditionFn();
    if (!result) setTimeout(() => waitUntil(conditionFn).then(resolve), delay);
    else resolve();
  });
};

export const onBubbleClick = async (props = {}) => {
  const {
    toggleValue,
    openLastConversation,
    openNewConversation,
    conversation,
  } = props;
  const {
    isOpen,
    lazyLoad,
    baseUrl,
    websiteToken,
    isSecondWidget,
  } = window.$hoory;
  if (lazyLoad) {
    window.$hoory.lazyLoad = false;
    window.$hoory.isLoading = true;

    /**
     * Lazy load the second widget
     */
    if (isSecondWidget) {
      document.body.classList.add('internal-widget');
      window.parent.postMessage('lazy-load-second-widget', '*');
    }

    IFrameHelper.createFrame({
      baseUrl,
      websiteToken,
      isSecondWidget,
    });
  }

  if (
    isOpen !== toggleValue ||
    openNewConversation ||
    openLastConversation ||
    conversation
  ) {
    const newIsOpen = toggleValue === undefined ? !isOpen : toggleValue;

    window.$hoory.isOpen = newIsOpen;

    /**
     * Disable scrolling on the body when the chat widget is open
     */
    const ACTIVE_CLASS = 'hoory-widget-is-active';
    if (window.innerWidth < 667) {
      toggleClass(document.documentElement, ACTIVE_CLASS);
    } else if (hasClass(document.documentElement, ACTIVE_CLASS)) {
      removeClasses(document.documentElement, ACTIVE_CLASS);
    }

    /**
     * Show/Hide the widget
     */
    if (newIsOpen) {
      addClasses(chatBubble, 'open');
      removeClasses(widgetHolder, 'woot--hide');
    } else {
      removeClasses(chatBubble, 'open');
      addClasses(widgetHolder, 'woot--hide');
    }

    await waitUntil(() => window.$hoory.hasLoaded);

    /**
     * Tell app that the widget is open/closed
     */
    IFrameHelper.events.onBubbleToggle({
      isOpen: newIsOpen,
      isLoading: window.$hoory.isLoading,
      openLastConversation,
      openNewConversation,
      conversation,
    });

    if (!newIsOpen) {
      chatBubble.focus();
    }

    const liveChatWidget = document.getElementById('chatwoot_live_chat_widget');
    if (!newIsOpen && liveChatWidget && liveChatWidget.contentWindow) {
      liveChatWidget.contentWindow.postMessage(
        {
          type: 'liveChatWidgetClosed',
        },
        '*'
      );
    }
  }
};

export const onClickChatBubble = () => {
  bubbleHolder.addEventListener('click', onBubbleClick);
};

export const addUnreadClass = () => {
  const holderEl = document.querySelector('.woot-widget-holder');
  addClasses(holderEl, 'has-unread-view');
  addClasses(chatBubble, 'woot--close');
};

export const removeUnreadClass = () => {
  const holderEl = document.querySelector('.woot-widget-holder');
  removeClasses(holderEl, 'has-unread-view');
  removeClasses(chatBubble, 'woot--close');
};
