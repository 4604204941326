export const SDK_CSS = `
:root {
  --b-100: #F2F3F7;
  --s-700: #37546D;
}

html.hoory-widget-is-active,
html.hoory-widget-is-active body {
  position: static !important;
  transform: none !important;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
  height:100vh !important;
}

html.hoory-widget-is-active body:not(.internal-widget) {
  background: #ffffff !important;
}


.woot-widget-holder {
  display: flex;
  box-shadow: 0 5px 40px rgba(0, 0, 0, .16);
  will-change: transform, opacity, width, height, max-height;
  transform-origin: right bottom;
  opacity: 1;
  transform: scale(1) translateY(0);
  overflow: hidden !important;
  position: fixed !important;
  z-index: 2147483000 !important;
  transition: transform 300ms cubic-bezier(0.04, 0.57, 0.46, 1.02) 0s, width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, opacity 100ms ease 0s;
}

.woot-widget-holder:not(.has-unread-view) {
  background: #fff;
}

.woot-widget-holder.woot-elements--left {
  transform-origin: left bottom !important;
}

.woot-widget-holder.woot-widget-holder--flat {
  box-shadow: none;
  border-radius: 0;
  border: 1px solid var(--b-100);
}

.woot-widget-holder iframe {
  border: 0;
  height: 100% !important;
  width: 100% !important;
  max-height: 100vh !important;
}

.woot-widget-holder.has-unread-view {
  border-radius: 0 !important;
  min-height: 80px !important;
  height: auto;
  bottom: 94px;
  box-shadow: none !important;
  border: 0;
}

.woot-widget-bubble {
  background: #623bea;
  border-radius: 100px;
  border-width: 0px;
  bottom: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, .16) !important;
  cursor: pointer;
  height: 64px;
  padding: 0px;
  position: fixed;
  user-select: none;
  width: 64px;
  z-index: 2147482000 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.woot-widget-bubble:active,
.woot-widget-bubble:focus {
  outline: none;
}

.woot-widget-bubble.woot-widget-bubble--flat {
  border-radius: 0;
}

.woot-widget-holder.woot-widget-holder--flat {
  bottom: 90px;
}

.woot-widget-bubble.woot-widget-bubble--flat {
  height: 56px;
  width: 56px;
}

.woot-widget-bubble.woot-widget-bubble--flat svg {
  margin: 16px;
}

.woot-widget-bubble.woot-widget-bubble--flat.woot--close::before,
.woot-widget-bubble.woot-widget-bubble--flat.woot--close::after {
  left: 28px;
  top: 16px;
}

.woot-widget-bubble.unread-notification::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background: #ff4040;
  border-radius: 100%;
  top: 0px;
  right: 0px;
  border: 2px solid #ffffff;
  transition: background 0.2s ease;
}

.woot-widget-bubble.woot-widget--expanded {
  direction: ltr !important;
  bottom: 24px;
  display: flex;
  height: 48px !important;
  width: auto !important;
  align-items: center;
  transform: translateX(0) scale(0.92) rotate(0deg) !important;
}
.woot-widget-bubble.woot-widget--expanded img {
  width: 38px !important;
  height: 38px !important;
  margin: 0 38px 0 28px;
}

.woot-widget-bubble.woot-widget--expanded div {
  align-items: center;
  color: #fff;
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  padding-right: 20px;
  width: auto !important;
  margin-left: 25px;
  padding-right: 15px;
}

.woot-widget-bubble.woot-widget--expanded.woot-widget-bubble-color--lighter div{
  color: var(--s-700);
}

.woot-widget-bubble.woot-widget--expanded svg {
  margin: 14px 8px 14px 14px;
  transform: scale(0.88);
}

.woot-widget-bubble.woot-elements--left {
  left: 20px;
}

.woot-widget-bubble.woot-elements--right {
  right: 20px;
}

.woot-widget-bubble:hover:not(:active) {
  transform: scale(1);
}

.woot-widget-bubble svg {
  all: revert;
}

.woot-widget-bubble .bubble-interaction-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.woot-widget-bubble .bubble-interaction-wrapper div {
  user-select: none;
  top: 0px;
  bottom: 0px;
  width: 100%;
  transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
  position: absolute;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.woot-widget-bubble .open-icon-wrapper {
  opacity: 1;
  transform: rotate(0deg) scale(1);
}
.woot-widget-bubble .close-icon-wrapper {
  color: #fff;
  opacity: 0;
  transform: rotate(-60deg) scale(0);
}
.woot-widget-bubble.open .open-icon-wrapper {
  opacity: 0;
  transform: rotate(30deg) scale(0);
}
.woot-widget-bubble.open .close-icon-wrapper {
  opacity: 1;
  transform: rotate(0deg) scale(1);
}

.woot-widget-bubble .open-icon-wrapper svg {
  transform: scale(0.85);
  position: relative;
  top: -2px;
}


.woot-widget-bubble.woot-widget-bubble-color--lighter path{
  fill: #0D0D0D
}

@media only screen and (min-width: 667px) {
  .woot-widget-holder.woot-elements--left {
    left: 20px;
  }
  .woot-widget-holder.woot-elements--right {
    right: 20px;
  }
}

.woot--close:hover {
  opacity: 1;
}

.woot-widget-bubble-color--lighter.woot--close::before, .woot-widget-bubble-color--lighter.woot--close::after {
  background-color: var(--s-700);
}

.woot-widget--without-bubble {
  bottom: 20px !important;
}

.woot-widget-holder.woot--hide {
  opacity: 0;
  transform: scale(0) translateY(40px);
}

.woot-widget-bubble {
  transform-origin: center;
  will-change: transform, opacity;
  transform: translateX(0) scale(0.86) rotate(0deg);
  transition: transform 150ms ease, opacity 100ms ease, visibility 0ms linear 0ms, bottom 0ms linear 0ms;
}

@media only screen and (max-width: 667px) {
  body:not(.internal-widget) .woot-widget-holder:not(.woot--hide,.has-unread-view) {
    height: 100%;
    right: 0;
    top: 0;
    width: 100%;
  }
  .woot-widget-holder.woot--hide {
    height: 0;
  }

  .woot-widget-holder iframe {
    min-height: 100% !important;
  }

  .woot-widget-holder {
    transition: transform 300ms cubic-bezier(0.04, 0.57, 0.46, 1.02) 0s !important;
    transform-origin: center bottom;
  }
  .woot-widget-holder.has-unread-view {
    height: auto;
    right: 0;
    width: auto;
    bottom: 0;
    top: auto;
    max-height: 100vh;
    padding: 0 8px;
  }

  .woot-widget-holder.has-unread-view iframe {
    min-height: unset !important;
  }

 .woot-widget-holder.has-unread-view.woot-elements--left {
    left: 0;
  }

  .woot-widget-bubble.woot--close {
    bottom: 60px;
    opacity: 0;
    z-index: -1 !important;
  }
}

@media only screen and (min-width: 667px) {
  .woot-widget-holder {
    border-radius: 16px;
    bottom: 104px;
    height: calc(94% - 84px);
    max-height: 704px !important;
    width: 400px !important;
  }
  .woot-widget-holder.woot--expanded {
    max-height: 754px !important;
    height: calc(95% - 64px - 20px) !important;
    width: 625px !important;
  }
}
@media only screen and (min-width: 667px) and (max-width: 850px) {
 .woot-widget-holder.woot--expanded {
   width: calc(100% - 40px) !important;
 }
}

.woot-hidden {
  display: none !important;
}
.woot-hidden.woot-mini {
  display: block !important;
}
.woot-hidden.woot-mini button {
  width: 38px !important;
  height: 38px !important;
  padding: 5px !important;
}
.woot-hidden.woot-mini #woot-widget--expanded__text {
  display: none !important;
}
.woot-hidden.woot-mini .bubble-interaction-wrapper,
.woot-hidden.woot-mini .open-icon-wrapper,
.woot-hidden.woot-mini .open-icon-wrapper svg {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}
.woot-hidden.woot-mini .open-icon-wrapper img {
  width: calc(100% + 10px) !important;
  height: calc(100% + 10px) !important;
  position: relative;
  margin: -5px;
  padding: 0;
}


.woot-widget-bubble-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0;
  object-fit: cover;
}

/* Loading second hoory widget */
body.internal-widget .woot-widget-holder {
  border-radius: 16px;
  bottom: 104px;
  height: calc(94% - 84px);
  max-height: 704px !important;
  width: 400px !important;
}
body.internal-widget .woot-widget-holder.woot--expanded {
  max-height: 754px !important;
  height: calc(95% - 64px - 20px) !important;
  width: 625px !important;
}
body.internal-widget .woot-widget-holder.woot-elements--left {
  left: 20px;
}
body.internal-widget .woot-widget-holder.woot-elements--right {
  right: 20px;
}

.second-hoory-iframe {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2147483001;
  border: none;
  border-radius: 16px;
  height: 90px;
  width: 130px;
}

.second-hoory-iframe.hide-message-bubble {
  width: 0;
  height: 0;
}

.second-hoory-iframe.right-position {
  left: auto;
  right: 0;
}

.second-hoory-iframe.is-2d-active {
  height: 100%;
  width: 460px !important;
  max-width: 100% !important;
}
@media only screen and (max-width: 460px) {
  .second-hoory-iframe {
    border-radius: 0;
  }
}

.bubble-loading-wrapper {
  display: none !important;
  width: 100%;
  height: 100%;
  border: 5px solid rgb(182 1 1 / 20%);
  border-radius: 50%;
  border-top-color: rgb(255 255 255 / 88%);
  position: absolute;
}

.lazy-loading-hoory:not(.woot-widget--expanded) .bubble-loading-wrapper {
  display: block !important;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.woot--bubble-holder button:not(.woot-widget-bubble-color--lighter) .path-1,
.woot--bubble-holder button:not(.woot-widget-bubble-color--lighter) .path-2,
.woot--bubble-holder button:not(.woot-widget-bubble-color--lighter) .path-3 {
  fill: white;
  stroke: currentColor;
}
.woot--bubble-holder button:not(.woot-widget-bubble-color--lighter) .path-4,
.woot--bubble-holder button:not(.woot-widget-bubble-color--lighter) .path-5 {
  fill: currentColor;
}
.woot--bubble-holder button:not(.woot-widget-bubble-color--lighter) .path-6 {
  fill: white;
}

.woot--bubble-holder button.woot-widget-bubble-color--lighter .path-1,
.woot--bubble-holder button.woot-widget-bubble-color--lighter .path-2,
.woot--bubble-holder button.woot-widget-bubble-color--lighter .path-3 {
  stroke: #0D0D0D;
  fill: white;
}
.woot--bubble-holder button.woot-widget-bubble-color--lighter .path-4,
.woot--bubble-holder button.woot-widget-bubble-color--lighter .path-5,
.woot--bubble-holder button.woot-widget-bubble-color--lighter .path-6 {
  fill: #0D0D0D;
}
`;
