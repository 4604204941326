import md5 from 'md5';

const REQUIRED_USER_KEYS = ['avatar_url', 'email', 'name'];
const ALLOWED_USER_ATTRIBUTES = [...REQUIRED_USER_KEYS, 'identifier_hash'];

export const getUserCookieName = () => {
  const SET_USER_COOKIE_PREFIX = 'cw_user_';
  const { websiteToken: websiteIdentifier } = window.$hoory;
  return `${SET_USER_COOKIE_PREFIX}${websiteIdentifier}`;
};

export const getUserString = ({ identifier = '', user }) => {
  const userStringWithSortedKeys = ALLOWED_USER_ATTRIBUTES.reduce(
    (acc, key) => `${acc}${key}${user[key] || ''}`,
    ''
  );
  return `${userStringWithSortedKeys}identifier${identifier}`;
};

export const computeHashForUserData = (...args) => md5(getUserString(...args));

export const hasUserKeys = user =>
  REQUIRED_USER_KEYS.reduce((acc, key) => acc || !!user[key], false);

export const setUserFromUrlIfAvailable = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userId = urlParams.get('hoory.user[identifier]');
  const user = {};
  if (urlParams.get('hoory.user[email]')) {
    user.id = urlParams.get('hoory.user[email]');
  }
  if (urlParams.get('hoory.user[name]')) {
    user.name = urlParams.get('hoory.user[name]');
  }
  if (urlParams.get('hoory.user[avatar_url]')) {
    user.avatar_url = urlParams.get('hoory.user[avatar_url]');
  }
  if (urlParams.get('hoory.user[phone_number]')) {
    user.phone_number = urlParams.get('hoory.user[phone_number]');
  }

  if (userId && Object.keys(user).length) {
    window.$hoory.setUser(userId, user);
  }
};
